html, body {
    height: 100%;
    margin: 0;
}

#root {
    width: 100%;
    height: 100%;
}

#nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

#logo {
    width: 60px;
}

img {
    width: 20px;
    padding: 5px;
}

#hero {
    height: 100%;
    width: 100%;
    background-image: url('../public/background.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;  
}

#meditate {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-49%, -60%);
}